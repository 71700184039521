import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import ReactBeforeSliderComponent from 'react-before-after-slider-component';
import 'react-before-after-slider-component/dist/build.css';

import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import Container from 'common/components/UI/Container';
import FeatureBlock from 'common/components/FeatureBlock';
import { SectionHeader } from '../app-minimal.style';
import SectionWrapper, { ThumbWrapper, TextWrapper } from './choose-us.style';

import Before5 from '../../../common/assets/image/beforeAfter/avant5.jpg';
import After5 from '../../../common/assets/image/beforeAfter/après5.jpg';

const ChooseUs = () => {
  const Data = useStaticQuery(graphql`
    query {
      appMinimalJson {
        chooseUs {
          title
          description
          features {
            id
            title
            description
          }
          thumbnail {
            childImageSharp {
              gatsbyImageData(
                width: 724
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
  `);
  const { title, description, thumbnail, features } =
    Data.appMinimalJson.chooseUs;

  const FIRST_IMAGE = {
    imageUrl: Before5
  };
  const SECOND_IMAGE = {
    imageUrl: After5
  };


  return (
    <SectionWrapper>
      <Container>
      <SectionHeader className="section-header-choose-us">
        <Heading content={title} />
        <Text content={description} />
      </SectionHeader>
        <ThumbWrapper>
          <ReactBeforeSliderComponent
            firstImage={FIRST_IMAGE}
            secondImage={SECOND_IMAGE}
            className="picture-before-after"
          />
        </ThumbWrapper>

        <TextWrapper>
          {features.map((item) => (
            <FeatureBlock
              key={`app-feature--key${item.id}`}
              iconPosition="left"
              icon={<Text as="span" content={'0' + item.id} />}
              title={<Heading as="h3" content={item.title} />}
              description={<Text content={item.description} />}
            />
          ))}
        </TextWrapper>
      </Container>
    </SectionWrapper>
  );
};

export default ChooseUs;
