import React from 'react';
import { ThemeProvider } from 'styled-components';
import { Modal } from '@redq/reuse-modal';
import { theme } from 'common/theme/appModern';
import { ResetCSS } from 'common/assets/css/style';
import Sticky from 'react-stickynode';

import Navbar from 'containers/AppModern/Navbar';
import Banner from 'containers/AppMinimal/Banner';
import Newsletter from 'containers/AppModern/Newsletter';
import Footer from 'containers/AppMinimal/Footer';

import Testimonials from 'containers/AppCreative/Testimonials';
import CallToAction from 'containers/AppCreative/CallToAction';
import ChooseUs from 'containers/AppMinimal/ChooseUs';
import FeatureSection from 'containers/SassMinimal/FeatureSection';
import Pricing from 'containers/WebAppCreative/Pricing';
import QualitySection from 'containers/Agency/QualitySection';
import Faqs from 'containers/AppCreative/Faq';
import KeyFeatures from 'containers/AppCreative/KeyFeatures';
import Experiences from 'containers/AppCreative/Experience';
import AvailableStore from 'containers/AppCreative/AvailableStore';
import Faq from 'containers/AppClassic/Faq';

import GlobalStyle, {
  AppWrapper,
  ContentWrapper,
} from 'containers/AppModern/appModern.style';
import '@redq/reuse-modal/es/index.css';

import Seo from 'components/seo';

const AppModern = () => {
  return (
    <ThemeProvider theme={theme}>
      <>
        <Seo title="Cryogenite" />
        <Modal />
        <ResetCSS />
        <GlobalStyle />

        <AppWrapper>
        <Sticky top={0} innerZ={9999} activeClass="sticky-active">
            <Navbar />
          </Sticky>
          
          <ContentWrapper>
            <Banner />
            <ChooseUs />
            {/* <QualitySection /> */}
            {/* <Experiences /> */}
            <FeatureSection />
            {/* <CallToAction />
            <Pricing /> */}
            {/* <Testimonials /> */}
            {/* <AvailableStore /> */}
          </ContentWrapper>
          <Footer />
        </AppWrapper>
      </>
    </ThemeProvider>
  );
};
export default AppModern;
